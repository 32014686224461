import React from "react";
import unpivot from '../images/unpivot.png'


export const MainGif = (props) => {
    return (
        <div>
            <img src={unpivot} alt="unpivot"></img>
        </div>
    );
};